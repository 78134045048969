module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-canonical-urls-virtual-168d1bef93/3/buildhome/.yarn/berry/cache/gatsby-plugin-canonical-urls-npm-5.13.0-236c6db6d0-10c0.zip/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.ds-mfgengineering.com/"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-ed02ebcb57/3/buildhome/.yarn/berry/cache/gatsby-plugin-google-tagmanager-npm-5.13.0-6ef9bd033b-10c0.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TWMVX2BF","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"DSI","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-b4441dc4f8/3/buildhome/.yarn/berry/cache/gatsby-plugin-catch-links-npm-5.13.0-7af5ebd768-10c0.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-337c8916db/3/buildhome/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.13.0-d134c631ce-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DSI","short_name":"DSI","description":"DSI","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/icons/android-chrome-192x192.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"2314289cccbd286b58dcefa9d2748ee6"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-nprogress-virtual-9f1c124cc5/3/buildhome/.yarn/berry/cache/gatsby-plugin-nprogress-npm-5.13.0-d1cda3b83f-10c0.zip/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#000000"},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-9c4c2abb90/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
